(function () {
	angular.module('Plania').directive('imageGallery', function () {
        return {
            restrict: 'E',
            scope: {
                reload: '=reload',
                edit: '=edit', // access to edit
                delete: '=delete', // access to delete
                images: '=images',
                inline: '=inline',
                columnSize: '=columnSize',
                guidBuilding: '=guidBuilding',
                guidComponent: '=guidComponent',
                guidEquipment: '=guidEquipment',
                guidEstate: '=guidEstate',
                guidPeriodicTask: '=guidPeriodicTask',
                guidRequest: '=guidRequest',
                guidWorkOrder: '=guidWorkOrder',
                guidCondition: '=guidCondition',
				guidDeviation: '=guidDeviation',
				guidArea: '=guidArea',
            },
            controller: ['$scope', '$modal', 'ngTableParams', 'config', 'Repository', '$filter', 'TranslationService', controller],
            templateUrl: 'app/common/directives/views/imageGallery.html'
        };
    });

    function controller($scope, $modal, ngTableParams, config, repository, $filter, translationService) {
        $scope.documentsIsLoading = true;
        if ($scope.images) {
            $scope.images.forEach(function (image) {
                image.timestamp = new Date().toISOString();
            });
        }

        $scope.imageApiUrl = config.baseUrlApi + repository.apiData.image.url;

        $scope.photoColumnSize = $scope.columnSize ? $scope.columnSize : 3;
        $scope.photoColumn = 'col-md-' + $scope.photoColumnSize;

        $scope.photoOptions = [
            { value: 2, column: 6 },
            { value: 3, column: 4 },
            { value: 4, column: 3 },
            { value: 6, column: 2 }
        ];
        $scope.paginatedImages = [];

        $scope.imageGalleryTable = new ngTableParams({
            page: 1,
            count: 6,
            sorting: {  },
            filter: {  }
        }, {
            total: 0,
            counts: [6, 12, 24],
            filterDelay: 10,
            getData: function($defer, params) {
                if (!$scope.images)
                    return $defer.resolve();
                $scope.imageGalleryTable.settings().total = $scope.images.length;
                var index = (params.$params.page - 1) * params.$params.count;

                $scope.paginatedImages = angular.copy($scope.images.slice(index, index + params.$params.count));
                $defer.resolve($scope.images);

            }
        });

		$scope.$watch('images', function(newVal, oldVal){
			if (newVal === oldVal) return;
			$scope.imageGalleryTable.reload();

		});

        //Change grid
        $scope.photoGrid = function (size) {
            $scope.photoColumn = 'col-md-' + size;
            $scope.photoColumnSize = size;
        };

        $scope.reloadImages = function () {
            repository.getPaginated().then(function () {

            });
        };

        var resolveParams = function () {
            return {
                guidBuilding: $scope.guidBuilding,
                guidEquipment: $scope.guidEquipment,
                guidEstate: $scope.guidEstate,
                guidPeriodicTask: $scope.guidPeriodicTask,
                guidRequest: $scope.guidRequest,
                guidWorkOrder: $scope.guidWorkOrder,
                guidDeviation: $scope.guidDeviation,
				guidCondition: $scope.guidCondition,
				guidArea: $scope.guidArea,
                defaultCategory: $scope.defaultCategory
            };
		};

		$scope.getSubHtml = function (image) {
			var elements = ['<div>' + image.Description + '</div>'];

			if (image.UserCreatedByName) {
				elements.push([
					"<div>",
					"<span>",
					translationService.translate('web-imageGalleryItem-userCreatedByName', 'Opprettet av'),
					"</span>",

					"<span> ",
					image.UserCreatedByName,
					"</span>",

					"<span> ",
					$filter("date")(image.CreationDate, "dd.MM.yyyy HH:mm"),
					"</span>",
					"</div>"
				].join(""));
			}

			return '<div style="display: flex; flex-direction: column;">' + elements.join("") + '</div>';
		};

        $scope.addNewImage = function () {
            $modal.open({
                templateUrl: 'app/document/views/createImageModal.html',
                controller: 'CreateDocumentXEntityController',
                size: 'lg',
                resolve: {
                    params: resolveParams
                }
            }).result.then(function (images) {
                if (!images) return;

                images.forEach(function (image) {
                    $scope.images.push(image);
                });
                $scope.imageGalleryTable.reload();
                var lastPage = Math.ceil($scope.imageGalleryTable.settings().total / $scope.imageGalleryTable.count() );
                $scope.imageGalleryTable.page(lastPage);
			}, function (msg) {

            });
        };

        var getGuidEntity = function () {
            if ($scope.guidBuilding) {
                return $scope.guidBuilding;
            }
            if ($scope.guidEquipment) {
                return $scope.guidEquipment;
            }
            if ($scope.guidEstate) {
                return $scope.guidEstate;
            }
            if ($scope.guidPeriodicTask) {
                return $scope.guidPeriodicTask;
            }
            if ($scope.guidRequest) {
                return $scope.guidRequest;
            }
            if ($scope.guidWorkOrder) {
                return $scope.guidWorkOrder;
            }
            if ($scope.guidDeviation) {
                return $scope.guidDeviation;
            }
            if ($scope.guidCondition) {
                return $scope.guidCondition;
			}
			if ($scope.guidArea) {
				return $scope.guidArea;
			}
            return null;
		};

		var getEntityType = function () {
			if ($scope.guidBuilding) {
				return "Building";
			}
			if ($scope.guidEquipment) {
				return "Equipment";
			}
			if ($scope.guidEstate) {
				return "Estate";
			}
			if ($scope.guidPeriodicTask) {
				return "PeriodicTask";
			}
			if ($scope.guidRequest) {
				return "Request";
			}
			if ($scope.guidWorkOrder) {
				return "WorkOrder";
			}
			if ($scope.guidDeviation) {
				return "Deviation";
			}
			if ($scope.guidCondition) {
				return "Condition";
			}
			if ($scope.guidArea) {
				return "Area";
			}
			return null;
		};

		var onChange = function (image) {
            $scope.images.forEach(function (img) {
                if (image.IsDefault && img.Guid !== image.Guid)
					img.IsDefault = false;

				if (img.Guid === image.Guid) {
					img.IsDefault = image.IsDefault;
					img.IsAttachment = image.IsAttachment;
				}
            });

            $scope.images = _.sortBy($scope.images, function (img) { return !img.IsDefault; });
            $scope.imageGalleryTable.reload();
        };

        $scope.editImage = function (image) {
            $modal.open({
                templateUrl: 'app/document/views/editImageModal.html',
                controller: 'EditImageController',
                size: 'lg',
                resolve: {
                    params: function () {
                        return {
                            image: image,
							guidEntity: getGuidEntity(),
							entityType: getEntityType(),
                            deleteAccess: $scope.delete,
							onChange: onChange
                        };
                    }
                }
            }).result.then(function (result) {
                var index = _.findIndex($scope.images, {Guid: image.Guid});

                if (result === 'Deleted') {
                    $scope.images.splice(index, 1);
                }
                else {
                    planiaUtils.copyProperties(result, image);
                    $scope.images.splice(index, 1);
                    image.timestamp = new Date().toISOString();
                    $scope.images.splice(index, 0, image);
                }
                $scope.imageGalleryTable.reload();

                // ensure that page currently exists, it might be gone after deletion of image
                var currentPage = $scope.imageGalleryTable.page();
                var lastPage = Math.ceil($scope.imageGalleryTable.settings().total / $scope.imageGalleryTable.count() );
                if (currentPage > lastPage)
                    $scope.imageGalleryTable.page(lastPage);
            });
        };
    }
})();
